import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Baileymwjwinterpatrickincaddy from '../assets/images/baileymwjwinterpatrickincaddy.jpg';
import Honey from '../assets/images/honey.jpg';
import Moultriehh from '../assets/images/moultriehh.jpg';
import Moultriebigsign from '../assets/images/moultriebigsign.jpg';
import Mwjwinterbaileyathhroadsign from '../assets/images/mwjwinterbaileyathhroadsign.jpg';
import P2040037 from '../assets/images/p2040037.jpg';
import Bigpeach from '../assets/images/bigpeach.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Moultrie: 2-03-01" />
      <div>
        <TripHeader>
          <h2>Moultrie</h2>
          <p>2-03-01</p>
          <img
            src={Baileymwjwinterpatrickincaddy}
            alt="Bailey, Winter, Michael Wayne, & Patrick in Caddy about to leave for Moultrie"
          />
          <ImageCaption>
            Bailey, Winter, Michael Wayne, & Patrick in Caddy about to leave for
            Moultrie
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Honey} alt="Honey" />
            <ImageCaption>Honey</ImageCaption>
          </div>
          <div>
            <img src={Moultriehh} alt="Moultrie Huddle House" />
            <ImageCaption>Moultrie Huddle House</ImageCaption>
          </div>
          <div>
            <img src={Moultriebigsign} alt="Moultrie Sign" />
            <ImageCaption>Moultrie Sign</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          During our Moultrie trip we were fortunate enough to visit the local
          Huddle House Saturday night for supper. It was a truly great Huddle
          House. I will say it was the best one I have ever been to. The people
          were so very nice! Honey waited on us and she was the most delightful
          lady I have ever met. She was excited about the fan club visiting and
          gave Winter, Michael Wayne, and Bailey (the three that went) Huddle
          House key chains and actual Huddle House ash trays! She also signed a
          menu she gave us. We all agree that was the best Huddle House ever. If
          you are ever in the area you should definatly look Honey up!
        </p>
        <ImageGrid>
          <div>
            <img
              src={Mwjwinterbaileyathhroadsign}
              alt="Michael Wayne, Winter & Bailey"
            />
            <ImageCaption>Michael Wayne, Winter & Bailey</ImageCaption>
          </div>
          <div>
            <img src={P2040037} alt="Phone" />
            <ImageCaption>Phone</ImageCaption>
          </div>
          <div>
            <img src={Bigpeach} alt="Big Peach" />
            <ImageCaption>Big Peach</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
